.title {
  font-weight: 600;
  font-size: 20px;
  line-height: 175%;
  color: #001f4d;
}

.form-upload {
  margin-top: 20px !important;
}

.action {
  margin-top: 40px;

  .cancel {
    background: none;
    background-color: #c4c4c4;
    color: #001f4d;
  }
}

.previewMedia {
  width: 360px;
  margin-top: 30px;

  & img,
  video {
    width: 100%;
    display: block;
  }
}

.file-upload {
  width: auto !important;
}

// .tooltip {
//   background-color: #d9d9d9;
//   box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
//   color: #000000;
//   font-size: 14px;
//   border: 1px solid #000000;
//   line-height: 20px;
// }

// .arrow {
//   color: #d9d9d9;
//   left: 10% !important;
//   position: absolute;
//   &:before {
//     border: 1px solid #000000;
//   }
// }
