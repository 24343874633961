.modalConfirm {
  & :global(.MuiDialog-paperWidthSm) {
    width: 500px;
  }
  & :global(.MuiDialogActions-root) {
    width: 50%;
    margin: auto;
  }
  & button {
    max-width: 100px;
    font-family: inherit;

    &:last-child {
      background-color: #c4c4c4;
      font-weight: 600;
    }
  }

  & .contentDeploy {
    & > p {
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
      text-align: center;
      color: #000000;
    }
  }
}
