@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:regular,bold,italic&subset=latin,latin-ext);
@font-face {
  font-family: 'Roboto-Regular';
  src: local('Roboto-Regular'), url(/static/media/Roboto-Regular.31012f9b.woff) format('woff');
}

@font-face {
  font-family: 'Roboto-Bold';
  src: local('Roboto-Bold'), url(/static/media/Roboto-Bold.3f2cbde6.woff) format('woff');
}

@font-face {
  font-family: 'Roboto-Italic';
  src: local('Roboto-Italic'), url(/static/media/Roboto-Italic.0d0bf30e.woff) format('woff');
}

@font-face {
  font-family: 'Roboto-Medium';
  src: local('Roboto-Medium'), url(/static/media/Roboto-Medium.e2d3fd03.woff) format('woff');
}
@font-face {
  font-family: 'OpenSans-Bold';
  src: local('OpenSans-Bold'), url(/static/media/OpenSans-Bold.6e27168f.ttf) format('truetype');
}

@font-face {
  font-family: 'OpenSans-BoldItalic';
  src: local('OpenSans-BoldItalic'), url(/static/media/OpenSans-BoldItalic.ed58bc28.ttf);
}

@font-face {
  font-family: 'OpenSans-ExtraBold';
  src: local('OpenSans-ExtraBold'), url(/static/media/OpenSans-ExtraBold.312244ca.ttf);
}

@font-face {
  font-family: 'OpenSans-ExtraBoldItalic';
  src: local('OpenSans-ExtraBoldItalic'), url(/static/media/OpenSans-ExtraBoldItalic.f539b62a.ttf);
}

@font-face {
  font-family: 'OpenSans-Italic';
  src: local('OpenSans-Italic'), url(/static/media/OpenSans-Italic.79c81eb6.ttf);
}

@font-face {
  font-family: 'OpenSans-Light';
  src: local('OpenSans-Light'), url(/static/media/OpenSans-Light.846a12ca.ttf);
}

@font-face {
  font-family: 'OpenSans-LightItalic';
  src: local('OpenSans-LightItalic'), url(/static/media/OpenSans-LightItalic.34eb76e1.ttf);
}

@font-face {
  font-family: 'OpenSans-Medium';
  src: local('OpenSans-Medium'), url(/static/media/OpenSans-Medium.f0cdede0.ttf);
}

@font-face {
  font-family: 'OpenSans-MediumItalic';
  src: local('OpenSans-MediumItalic'), url(/static/media/OpenSans-MediumItalic.4d4312a9.ttf);
}

@font-face {
  font-family: 'OpenSans-Regular';
  src: local('OpenSans-Regular'), url(/static/media/OpenSans-Regular.d7d7b835.ttf);
}

@font-face {
  font-family: 'OpenSans-SemiBold';
  src: local('OpenSans-SemiBold'), url(/static/media/OpenSans-SemiBold.d7261533.ttf);
}

@font-face {
  font-family: 'OpenSans-SemiBoldItalic';
  src: local('OpenSans-SemiBoldItalic'), url(/static/media/OpenSans-SemiBoldItalic.5d2b4174.ttf);
}

:root {
  font-size: 16px;
}

.MuiTableSortLabel-icon{
  display: none !important;
}

body {
  margin: 0;
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #FFFFFF;
  font-size: 14px;
  margin: 0px;
}

main {
  background-color: #F7F8FA;
}

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

a,
a:hover,
a:active {
  text-decoration: none;
  color: #4A4A4A;
}

.clearfix::after {
  content: "";
  clear: both;
  display: table;
}

ol,
ul {
  list-style: none;
  padding: 0px;
  margin: 0px;
}

input[type=text][disabled], input:disabled {
  cursor: not-allowed;
}

.text-danger {
  color: red;
}

.text-center {
  text-align: center;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-lowercase {
  text-transform: lowercase;
}

input[type=number] {
  -moz-appearance: textfield,
}
input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.ck-editor__editable_inline {
    min-height: 300px;
}
.ant-picker-dropdown {
    z-index: 9999 !important;
}
.styles_tableContainer__3WR7-{border-radius:10;box-shadow:none;margin-top:25}.styles_table__1bogK{font-family:"Roboto";font-weight:500;font-size:14}.styles_tableHeader__35rF6{padding:10;color:#001f4d;opacity:.6;border-bottom:none !important;background-color:#fff}.styles_tableBody__3s3FL:before{content:"-";display:block;line-height:8px;color:rgba(0,0,0,0)}.styles_skeleton__3AF9y{padding:25px 0px;margin-top:10}.styles_noDataMessage__18xV0{font-weight:500;margin-top:30px;text-align:center;font-size:1rem}.styles_errorMessage__3rfCn{font-weight:500;margin-top:30px;text-align:center;font-size:1rem;color:red}.styles_paginationField__3O0c5{width:100%}.styles_icon__1Pi2f{cursor:pointer}
.styles_title__wG5M6{font-weight:600;font-size:20px;line-height:175%;color:#001f4d}.styles_form-upload__gvrEk{margin-top:20px !important}.styles_action__3k9va{margin-top:40px}.styles_action__3k9va .styles_cancel__fvCod{background:none;background-color:#c4c4c4;color:#001f4d}.styles_previewMedia__2Ds7m{width:360px;margin-top:30px}.styles_previewMedia__2Ds7m img,.styles_previewMedia__2Ds7m video{width:100%;display:block}.styles_file-upload__2QBML{width:auto !important}
.styles_slideContainer__2yLpO{padding:21px 97px 21px 27px;background:#fff;border:1px solid #000;border-radius:15px;margin-bottom:30px}.styles_slideContainer__2yLpO h3{color:#001f4d;font-weight:600;font-size:20px}.styles_slideContainer__2yLpO .styles_data__27Ykz{margin-bottom:5px;color:#001f4d;font-size:16px}.styles_slideContainer__2yLpO .styles_data__27Ykz>div.MuiGrid-item:first-child{width:200px}.styles_slideContainer__2yLpO .styles_actions__3MvDi>button{padding:8px 24px;width:78px}.styles_slideContainer__2yLpO .styles_actions__3MvDi>button:first-child{margin:5px 0 17px}.styles_slideContainer__2yLpO .styles_actions__3MvDi .styles_cancel__3r-RZ{background:none;background-color:#c4c4c4;color:#001f4d}
.styles_title__1C9li{font-weight:600;font-size:20px;color:#001f4d}.styles_create-slide-btn__SsmnJ{margin:20px 0 30px}
.styles_modalConfirm__FX6CX .MuiDialog-paperWidthSm{width:500px}.styles_modalConfirm__FX6CX .MuiDialogActions-root{width:50%;margin:auto}.styles_modalConfirm__FX6CX button{max-width:100px;font-family:inherit}.styles_modalConfirm__FX6CX button:last-child{background-color:#c4c4c4;font-weight:600}.styles_modalConfirm__FX6CX .styles_contentDeploy__3_UYh>p{font-style:normal;font-weight:600;font-size:20px;line-height:24px;text-align:center;color:#000}
.styles_title__YoncY{font-weight:600;font-size:20px;line-height:175%;color:#001f4d}.styles_action__2l8fh{margin-top:40px}.styles_action__2l8fh .styles_cancel__2NdXV{background:none;background-color:#c4c4c4;color:#001f4d}.styles_previewMedia__23Uw5{width:360px;margin-top:30px}.styles_previewMedia__23Uw5 img,.styles_previewMedia__23Uw5 video{width:100%;display:block}.styles_file-upload__3KQxK{width:auto !important}
.ant-select-selector{height:100% !important}.ant-select-selection-item{line-height:48px !important}
.ant-select-selector{height:100% !important}.ant-select-selection-item{line-height:48px !important}
.commonTable .MuiTableBody-root .MuiTableCell-body{background-color:#fff}.commonTable .MuiTableCell-head{padding:15px 10px;font-family:"OpenSans-Bold";color:#000;font-size:16px}.commonTable.MuiPaper-elevation1{box-shadow:none;background:none !important;position:relative;min-height:750px;max-height:1000px}.commonTable .MuiTableSortLabel-root{color:#001f4d;cursor:default}.commonTable .MuiTableSortLabel-root:hover{color:#001f4d}.commonTable .MuiButton-text{padding:4px 8px}
