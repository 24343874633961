.slideContainer {
  padding: 21px 97px 21px 27px;
  background: #ffffff;
  border: 1px solid #000000;
  border-radius: 15px;

  h3 {
    color: #001f4d;
    font-weight: 600;
    font-size: 20px;
  }

  .data {
    margin-bottom: 5px;
    color: #001f4d;
    font-size: 16px;

    & > div:global(.MuiGrid-item):first-child {
      width: 200px;
    }
  }

  .actions {
    & > button {
      padding: 8px 24px;
      width: 78px;

      &:first-child {
        margin: 5px 0 17px;
      }
    }

    .cancel {
      background: none;
      background-color: #c4c4c4;
      color: #001f4d;
    }
  }

  margin-bottom: 30px;
}
