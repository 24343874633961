.tableContainer {
  // padding: '30px 20px';
  border-radius: 10;
  box-shadow: none;
  margin-top: 25;
}
.table {
  font-family: 'Roboto';
  font-weight: 500;
  font-size: 14;
}
.tableHeader {
  padding: 10;
  color: #001f4d;
  opacity: 0.6;
  // fontWeight: 600
  border-bottom: none !important;
  background-color: #fff;
}

.tableBody:before {
  content: '-';
  display: block;
  line-height: 8px;
  color: transparent;
}

.skeleton {
  padding: 25px 0px;
  margin-top: 10;
}
.noDataMessage {
  font-weight: 500;
  margin-top: 30px;
  text-align: center;
  font-size: 1rem;
}
.errorMessage {
  font-weight: 500;
  margin-top: 30px;
  text-align: center;
  font-size: 1rem;
  color: red;
}

.paginationField {
  width: 100%;
}

.icon {
  cursor: pointer;
}
