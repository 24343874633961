.commonTable {
    .MuiTableBody-root {
        .MuiTableCell-body {
            background-color: #fff;
        }
    }
    .MuiTableCell-head {
        padding: 15px 10px;
        font-family: 'OpenSans-Bold';
        color: #000000;
        font-size: 16px;
    }
    &.MuiPaper-elevation1 {
        box-shadow: none;
        background: none !important;
        position: relative;
        min-height: 750px;
        max-height: 1000px;
    }
    .MuiTableSortLabel-root{
        color: #001F4D;
        cursor: default;
        &:hover{
            color: #001F4D;
        }
    }

    .MuiButton-text{
        padding: 4px 8px;
    }
   
}