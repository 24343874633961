.title {
  font-weight: 600;
  font-size: 20px;
  line-height: 175%;
  color: #001f4d;
}

.action {
  margin-top: 40px;

  .cancel {
    background: none;
    background-color: #c4c4c4;
    color: #001f4d;
  }
}

.previewMedia {
  width: 360px;
  margin-top: 30px;

  & img,
  video {
    width: 100%;
    display: block;
  }
}

.file-upload {
  width: auto !important;
}
